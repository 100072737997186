
.industryMarket-content{
    .page-title {
        font-size: 20px;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .industry-data {
        background: #fff;
        padding: 20px;
        .data-item {
            width: 1%;
            flex: 1;
            padding-right: 60px;
            .data-title {
                line-height: 1;
                .title {
                    font-size: 18px;
                    font-weight: bold;
                }
                .subTitle {
                    font-size: 14px;
                    margin-left: 14px;
                }
            }
            .data-content {
                height: 300px;
                margin-top: 30px;
            }
        }
    }
    .industry-market {
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
        .market-item {
            width: calc(50% - 40px);
            background: #fff;
            margin: 0 0 20px 40px;
            padding: 20px;
            box-sizing: border-box;
            .market-title {
                color: #666;
                line-height: 1;
            }
            .market-number {
                color: #666;
                line-height: 1;
                margin-top: 6px;
                font-size: 16px;
            }
            .market-content {
                margin-top: 20px;
                height: 300px;
            }
        }
    }
    .industry-market-data {
        width: 100%;
        background: #fff;
        display: flex;
        .data-item {
            width: 33.333333%;
            padding: 30px 0;
            text-align: center;
            display: flex;
            justify-content: center;
            .iconfont {
                font-size: 24px;
                text-align: center;
                line-height: 1;
                color: #0084FF;
            }
            .right {
                padding-left: 12px;
                .text {
                    font-size: 18px;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 12px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .trend-wrapper {
        background: #fff;
        padding: 20px;
        .trend-category {
            display: flex;
            border: 1px solid #E5E5E5;
            .cate-left {
                width: 120px;
                background: #F8F8F8;
                p {
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    border-bottom: 1px solid #E5E5E5;
                    margin: 0;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .cate-right {
                width: 1%;
                flex: 1;
                .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    .el-checkbox {
                        margin-right: 0;
                        line-height: 50px;
                        padding-left: 48px;
                        width: 15%;
                        box-sizing: border-box;
                        &:nth-of-type(2) {
                            width: 85%;
                        }
                        &:nth-of-type(1), &:nth-of-type(2) {
                            border-bottom: 1px solid #E5E5E5;
                        }
                    }
                }
            }
        }
        .choose-number {
            margin-top: 30px;
            font-size: 18px;
            .number {
                margin-left: 20px;
                span {
                    color: #0084FF;
                }
            }
        }
        .trend-content {
            margin-top: 30px;
            height: 300px;
        }
    }

    .commodityTab {
        ::v-deep .el-tabs__header {
            margin: 0;
            .el-tabs__nav-wrap {
                padding: 0 30px;
            }
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
            .el-tabs__item.is-active {
                color: #FD4446;
            }
            .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #D1D2DB;
            }
        }
        ::v-deep .el-tabs__item {
            height: 80px;
            line-height: 80px;
            font-size: 20px;
            &:hover {
                color: #FD4446;
            }
        }
    }
    .scrollbar-content{
        height: calc(100vh - 200px);
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #0084FF;
        border-color: #0084FF;
    }
    ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #0084FF;
    }
    ::v-deep .el-checkbox__inner:hover {
        border-color: #0084FF;
    }
}

